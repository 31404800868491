<template>
  <!-- 重构 - 结算管理 - 生成结算单（付款） -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goReturn()">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">生成结算单（代理商--普惠）</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="180px"
          class="demo-ruleForm"
        >
          <el-form-item label="结算信息" class="ts"></el-form-item>
          <el-form-item label="结算单号" class="tsWidth">
            <div class="tsWidth">
              <el-input
                type="text"
                size="small"
                style="width: 199px"
                placeholder="请输入结算单号"
                v-model="ruleForm.billNo"
              ></el-input>
              <p style="color: red">
                为空时则按系统规则生产,否则按填写的单号生产。
              </p>
            </div>
          </el-form-item>
          <el-form-item label="选择合同" prop="contractId" class="tsWidth">
            <el-select
              v-model="ruleForm.contractId"
              size="small"
              placeholder="请选择合同"
              ><el-option
                v-for="item in fdNewContractLists"
                :key="item.contractId"
                :label="item.contractName"
                :value="item.contractId"
              ></el-option>
            </el-select>
            <el-button type="text" @click="previewContract">预览合同</el-button>
          </el-form-item>
          <el-form-item
            label="收费标准"
            prop="chargesSettlement"
            class="sfbzWidth"
          >
            <el-input
              type="textarea"
              maxlength="1000"
              resize="none"
              placeholder="请输入收费标准"
              show-word-limit
              v-model="ruleForm.chargesSettlement"
            ></el-input>
          </el-form-item>
          <el-form-item label="应结金额" class="btbzWitch">
            <el-table
              :data="ruleForm.fdNewProjectBOList"
              :highlight-current-row="true"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              class="tb-edit"
            >
              <el-table-column label="序号" type="index"> </el-table-column>
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settlementUserCount"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.settlementUserCount"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.settlementUserCount = zF.oninput2(
                        scope.row.settlementUserCount,
                        2
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                    :style="disabled1 ? 'display: none;' : ''"
                  ></el-input>
                  <span :style="disabled1 ? 'display: block;' : ''">{{
                    scope.row.settlementUserCount
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="标准"
                align="left"
                show-overflow-tooltip
                prop="settlementStandard"
                min-width="120"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.settlementStandard"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.settlementStandard = zF.oninput2(
                        scope.row.settlementStandard,
                        2
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                    :style="disabled1 ? 'display: none;' : ''"
                  ></el-input>
                  <span :style="disabled1 ? 'display: block;' : ''">{{
                    scope.row.settlementStandard
                  }}</span>
                </template></el-table-column
              >
              <el-table-column
                label="应付代理费"
                align="left"
                show-overflow-tooltip
                prop="payerAmount"
                min-width="130"
                ><template slot-scope="scope">
                  <span>{{ scope.row.payerAmount }}</span>
                </template></el-table-column
              >
              <el-table-column
                label="平台结算代理费"
                align="left"
                show-overflow-tooltip
                prop="settlementAmount"
                min-width="130"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.settlementAmount"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.settlementAmount = zF.oninput2(
                        scope.row.settlementAmount,
                        2
                      )
                    "
                  ></el-input>
                  <span>{{ scope.row.settlementAmount }}</span>
                </template></el-table-column
              >
            </el-table>
          </el-form-item>

          <el-form-item label="备注" prop="remark" class="sfbzWidth">
            <el-input
              type="textarea"
              maxlength="500"
              resize="none"
              placeholder="请输入备注"
              show-word-limit
              v-model="ruleForm.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="收付款账号信息" class="ts"></el-form-item>
          <el-form-item label="付款单位" prop="payerName">
            <el-input
              v-model="ruleForm.payerName"
              placeholder="请输入付款单位"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款单位" prop="payeeName">
            <el-input
              v-model="ruleForm.payeeName"
              placeholder="请输入收款单位"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="payeeBank">
            <el-input
              v-model="ruleForm.payeeBank"
              placeholder="请输入开户银行"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="payeeAccount">
            <el-input
              v-model="ruleForm.payeeAccount"
              placeholder="请输入银行账号"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item class="operation">
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="goReturn()"
              >返回</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="generatePreview('ruleForm', 1)"
              >预览结算单</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="generatePreview('ruleForm', 2)"
              >生成结算单</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查看班级明细 - loading -->
    <el-dialog
      title="查看课程明细"
      :visible.sync="dialogVisible1"
      width="50%"
      top="2%"
      center
      ><div id="pdf-cert1" style="height: 600px"></div>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog
      title="预览合同"
      :visible.sync="dialogVisible3"
      width="900px"
      top="2%"
      center
      ><contractManagementAdd
        :seeTH="true"
        :seeContractId="ruleForm.contractId"
        v-if="dialogVisible3"
      />
    </el-dialog>
    <!-- 预览结算单 - loading -->
    <el-dialog
      title="预览结算单"
      :visible.sync="dialogVisible2"
      width="50%"
      top="2%"
      center
      ><div id="pdf-cert2" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import contractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import { console } from "../../../utils/helper";
export default {
  name: "singleAdministrationChildrenSecondEdit",
  components: {
    contractManagementAdd,
  },
  data() {
    return {
      // 父页面带过的参数
      fatherData: {
        id: "", // 待结算列表无id;结算单管理有id
        type: "", // 1-收款结算单;2-付款结算单
        addEdit: "", // add-待结算列表;edit-结算单管理
      },
      // 页面数据
      ruleForm: {
        billNo:'',
        billId: "", // 结算单id
        contractId: "", // 合同
        compId: "", // 课程提供方id
        trainTypeId: "", // 培训类型id
        fdNewProjectBOList: [], // 结算明细 - 列表数据
        chargesSettlement: "", // 收费标准
        remark: "", // 备注
        payerName: "北京晟融信息技术有限公司", // 付款单位
        payeeName: "", // 收款单位
        payeeBank: "中国民生银行股份有限公司北京苏州街支行", // 开户银行
        payeeAccount: "154322354", // 银行账号
        settlementUserCount: 0, // 潜在字段 - 结算人数
        totalLessonNum: 0, // 潜在字段 - 总课时
        openLessonNum: 0, // 潜在字段 - 总公共课时
        payerAmount: 0, // 潜在字段 - 线上课程服务费（元）
      },
      // 数据校验
      rules: {
        // 选择合同
        contractId: [
          { required: true, message: "请选择合同", trigger: "change" },
        ],
        // 收费标准
        chargesSettlement: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
        // 付款单位
        payerName: [
          { required: true, message: "请输入付款单位", trigger: "blur" },
        ],
        // 收款单位
        payeeName: [
          { required: true, message: "请输入收款单位", trigger: "blur" },
        ],
        // 开户银行
        payeeBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        // 银行账号
        payeeAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
      },
      // 国家补贴标准列表 - 国家补贴标准（元）是否可以输入
      disabled1: false,
      // 查看班级明细 - loading
      dialogVisible1: false,
      // 预览结算单 - loading
      dialogVisible2: false,
      // 预览合同 - loading
      dialogVisible3: false,
      fdNewContractLists: [],
    };
  },
  created() {
    /* /biz/new/agentBill/generatePawBill */
    this.fatherData.id = JSON.parse(this.$route.query.editInfo).id;
    this.fatherData.toSettleNum = JSON.parse(
      this.$route.query.editInfo
    ).toSettleNum;
    this.fatherData.type = JSON.parse(this.$route.query.editInfo).type;
    this.fatherData.addEdit = JSON.parse(this.$route.query.editInfo).addEdit;
    this.fatherData.userList = JSON.parse(this.$route.query.editInfo).userList;
    console.log(this.fatherData.userList);
    this.generateSettlementDocument();
  },
  mounted() {},
  watch: {
    // 监听合同 - 变更：收费标准/付款单位
    "ruleForm.contractId": {
      handler: function (newVal) {
        // 判断合同数据
        let lData = [];
        for (let i = 0; i < this.fdNewContractLists.length; i++) {
          if (this.fdNewContractLists[i].contractId == newVal) {
            this.ruleForm.chargesSettlement =this.ruleForm.chargesSettlement || this.fdNewContractLists[i].chargesSettlement;
            this.ruleForm.payeeBank =
              this.fdNewContractLists[i].partyBBank || this.ruleForm.payeeBank;
            this.ruleForm.payeeAccount =
              this.fdNewContractLists[i].partyBAccount ||
              this.ruleForm.payeeAccount;
            this.ruleForm.payeeName = 
              this.fdNewContractLists[i].compName || this.ruleForm.payeeName;
            // this.ruleForm.payeeName = this.fdNewContractLists[i].agencyType == '20'?
            //   this.fdNewContractLists[i].compName || this.ruleForm.payeeName : '';
            lData = this.fdNewContractLists[i];
            console.log(lData);
            this.disabled1 = false;
          }
        }
      },
    },
  },
  methods: {
    // 面包屑 - 返回上个页面
    goReturn() {
      this.$router.push({
        path:
          this.fatherData.addEdit == "edit"
            ? "/web/financialSettlement/settlement/singleAdministration"
            : "/web/financialSettlement/settlement/toBeSettledList",
        query: {
          active: this.fatherData.type,
          refresh: true,
          multipleTable:
            this.fatherData.addEdit == "edit" ? "" : "multipleTable",
        },
      });
    },
    // 预览合同
    previewContract() {
      if (this.ruleForm.contractId) {
        this.dialogVisible3 = true;
      } else {
        this.$message.error("请选择合同");
      }
    },
    // 获取结算单数据
    generateSettlementDocument() {
      if (this.fatherData.addEdit == "add") {
        this.$post("/biz/new/agentBill/generatePawBill", {
          agencyId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              // 合同
              if (res.data.length) {
                this.fdNewContractLists = res.data; // 合同数据
                console.log(this.fdNewContractLists);
                this.ruleForm.contractId = res.data[0].contractId; // 默认赋值合同
              }
              // 结算明细 - 列表数据
              this.ruleForm.fdNewProjectBOList.push({
                settlementUserCount: this.fatherData.toSettleNum,
                settlementStandard: 0,
                payerAmount: 0,
                settlementAmount: 0,
              });
              // = res.data.selectedNodes;
              // console.log(res.data.selectedNodes);
            }
          })
          .catch(() => {
            return;
          });
      }
      if (this.fatherData.addEdit == "edit") {
        this.$post("/biz/new/billAgent/operation/pawGetInfo", {
          billId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              console.warn(res.data.chargesSettlement);
              this.ruleForm.chargesSettlement=res.data.chargesSettlement; // 结算标准

              this.ruleForm.fdNewProjectBOList = res.data.projectBOList; // 结算明细 - 列表数据
              if (res.data.fdNewContract.length) {
                this.fdNewContractLists = res.data.fdNewContract; // 合同数据
                this.ruleForm.contractId = res.data.contractId || ""; // 默认赋值合同
              }
              this.ruleForm.billNo = res.data.billNo;
              this.ruleForm.payeeName = res.data.payeeName;
              this.ruleForm.payeeBank = res.data.payeeBank;
              this.ruleForm.payeeAccount = res.data.payeeAccount;
              this.ruleForm.payerName = res.data.payerName;
              this.ruleForm.payerAmount = res.data.payerAmount; // 潜在字段 - 平台服务费（元）
              this.ruleForm.remark = res.data.remark; //
              this.ruleForm.billId = this.fatherData.id; // 结算单id
              this.ruleForm.fdNewProjectBOList = res.data.billDetails
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    // 算法('列表当前行的下标','列表当前行的数据','list-列表/contract-合同')
    algorithm(index, type) {
      if (type == "list") {
        this.ruleForm.fdNewProjectBOList[index].payerAmount = (
          Number(this.ruleForm.fdNewProjectBOList[index].settlementUserCount) *
          Number(this.ruleForm.fdNewProjectBOList[index].settlementStandard)
        ).toFixed(2);
        this.ruleForm.fdNewProjectBOList[index].settlementAmount = (
          Number(this.ruleForm.fdNewProjectBOList[index].settlementUserCount) *
          Number(this.ruleForm.fdNewProjectBOList[index].settlementStandard)
        ).toFixed(2);
      }
    },
    // 生成/预览 - 结算单按钮;1-预览/2-生成
    generatePreview(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let hData = {
            billNo:this.ruleForm.billNo,
            contractId: this.ruleForm.contractId, // 合同
            chargesSettlement: this.ruleForm.chargesSettlement, // 收费标准
            remark: this.ruleForm.remark, // 备注
            payerName: this.ruleForm.payerName, // 付款单位
            payeeName: this.ruleForm.payeeName, // 收款单位
            payeeBank: this.ruleForm.payeeBank, // 开户银行
            payeeAccount: this.ruleForm.payeeAccount, // 银行账号
          };
          if (this.ruleForm.billId) {
            hData.billId = this.ruleForm.billId;
          }
          hData.billDetails = this.ruleForm.fdNewProjectBOList; // 应结金额 结算信息
          hData.userList = this.fatherData.userList // 潜在字段 - 学员数组
          if (type == 1) {
            this.$post("/biz/new/agentBill/pawAgentBillPdfPreview", hData)
              .then((ret) => {
                this.dialogVisible2 = true;
                this.$nextTick(() => {
                  pdf.embed(ret.data, "#pdf-cert2");
                  console.log(ret);
                });
              })
              .catch(() => {
                return;
              });
          } else {
            if (this.fatherData.addEdit == "add") {
              this.$post("/biz/new/agentBill/savePawBill", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.goReturn();
                    this.$message({
                      message: "生成成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$post("/biz/new/billAgent/operation/pawUpdate", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.goReturn();
                    this.$message({
                      message: "编辑成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="less">
.demo-ruleForm {
  position: relative;
  .ckbjmx {
    position: absolute;
    left: 900px;
  }
  .el-form-item {
    width: 520px;
  }
  .ts {
    .el-form-item__label {
      font-size: 17px;
      font-weight: 600;
    }
  }
  .btbzWitch {
    min-width: 100%;
    .el-table__header-wrapper {
      th {
        padding: 0;
        background: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
  }
  .tsWidth {
    width: 550px;
    .el-form-item__content {
      display: flex;
      .el-button {
        margin-left: 0.75rem;
      }
    }
    // .el-input {
    //   width: 280px;
    //   float: left;
    // }
    // .el-button {
    //   float: right;
    // }
  }
  .sfbzWidth {
    min-width: 1100px;
  }
  .operation {
    width: 800px;
    .el-button {
      width: 100px;
      margin: 0 15px;
    }
  }
  .tb-edit .el-input {
    display: none;
  }

  .tb-edit .current-row .el-input {
    display: block;
  }

  .tb-edit .current-row .el-input + span {
    display: none;
  }
}
.demo-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
</style>